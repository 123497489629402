/**
 * A component that displays the details of an order item.
 * @prop {object} detail - the details of the order item.
 * @prop {function} deleteTooth - a function to delete a tooth from the order item.
 * @prop {function} deleteAll - a function to delete all the details of the order item.
 * @prop {function} edit - a function to edit the order item.
 */
const DetailsPanel = (props) => {
    const { detail, deleteTooth, deleteAll, edit, canEdit = true } = props;

    return (
        <div className="details-block">
            <div className="teeth-block">
                {detail?.teeth?.map((item) => (
                    <div
                        className={
                            "tooth-selected-block" +
                            (detail?.teeth?.length === 1 ? " border-0" : "")
                        }
                        key={item}
                    >
                        #{item}
                        {detail?.teeth.length > 1 && canEdit ? (
                            <img
                                className="delete-detail-tooth align-top cursor-pointer"
                                src="/images/icons/cross.svg"
                                height={16}
                                onClick={() => deleteTooth(item)}
                            />
                        ) : null}
                    </div>
                ))}
            </div>
            <div className="details-comments">
                <div className="details-label mb-2">Комментарий</div>
                <div className="details-text">{detail?.description}</div>
            </div>
            {canEdit && (
                <>
                    <div className="details-buttons d-flex">
                        <button className="w-100 btn" onClick={edit}>
                            <img src="/images/icons/edit.svg" height={16} />
                            Редактирвать
                        </button>
                        <button className="w-100 btn" onClick={deleteAll}>
                            <img src="/images/icons/trash.svg" height={16} />
                            Удалить эти детали
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default DetailsPanel;
