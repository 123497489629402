import { Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

const ShowOrderInProgressModal = (props) => {
    const params = useParams();
    const { workzoneId, orderId } = params;

    const navigate = useNavigate();
    const handleClose = () => navigate(`/workzone/${workzoneId}/plane`);

    return (
        <Modal show={true} onHide={handleClose} backdrop='static' size='xl' className='modal-delete' centered>
            <Modal.Header closeButton>
                <Modal.Title className='mt-3'>Работа над проектом #{orderId}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='text-center py-2'></Modal.Body>
        </Modal>
    );
};

export default ShowOrderInProgressModal;
