import { Link, Outlet, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { useGetOrdersPinQuery, useGetOrdersQuery, useLazyGetOrdersQuery } from '../../../app/api/orderApiSlice';
import WorkzonesPanel from './WorkzonesPanel';
import PlaneColumn from './PlaneColumn';
import { useEffect, useState } from 'react';

const kanbanCols = [
    { id: 8, title: 'Черновики' },
    { id: 0, title: 'Исходящие проекты' },
    { id: 1, title: 'Входящие проекты' },
    { id: 2, title: 'Согласование объёма работ' },
    { id: 3, title: 'В работе' },
    { id: 4, title: 'Согласование выполненных работ' },
    { id: 5, title: 'Доработка' },
    { id: 6, title: 'Готовые' },
    { id: 7, title: 'Отменённые' },
];

const PlanePage = () => {
    let params = useParams();
    const { workzoneId } = params;

    const { data } = useGetOrdersQuery({ workzoneId, sort: 'createdDate', direction: 0 });
    const { data: pins } = useGetOrdersPinQuery();

    const [orders, setOrders] = useState([]);

    useEffect(() => {
        if (data?.length > 0 && pins?.orders) {
            const pinOrders = JSON.parse(pins?.orders);
            setOrders(
                data.map((item) => ({
                    ...item,
                    isPinned: pinOrders.some((x) => x === item.id),
                }))
            );
        } else {
            setOrders(data);
        }
    }, [data, pins]);

    console.log('orders', orders);

    return (
        <div>
            <div className='plane-header mb-2'>
                <WorkzonesPanel currentWorkzone={params.workzoneId} />
                <Row className='pt-2'>
                    <Col className='align-self-center'>
                        <button className='btn btn-outline-secondary btn-sm-dental me-1 active'>Все</button>
                        <button className='btn btn-outline-secondary btn-sm-dental me-1'>Исходящие</button>
                        <button className='btn btn-outline-secondary btn-sm-dental'>Входящие</button>
                    </Col>
                    <Col className='text-end'>
                        <Link className='btn btn-secondary me-2' to={'profile'}>
                            Профиль
                        </Link>
                        <Link className='btn btn-secondary me-3' to={'orders/0'}>
                            Создать заказ
                        </Link>
                        {/*<OverlayTrigger
                            placement='top'
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip className='dental-tooltip' id='add-to-favorite'>
                                    В избранное
                                </Tooltip>
                            }
                        >
                            <button className='btn btn-icon-noborder icon-star'>
                                <img src='/images/icons/star.svg' width={16} height={16} />
                            </button>
                        </OverlayTrigger>*/}
                    </Col>
                </Row>
            </div>
            <Outlet />
            <Row className='flex-nowrap plane-block'>
                {kanbanCols.map((item) => (
                    <PlaneColumn
                        key={item.id}
                        orders={orders?.filter((order) => {
                            let orderStatus = order?.status;
                            if (orderStatus === 0) {
                                orderStatus = order.workzoneId === parseInt(workzoneId) ? 0 : 1;
                            }
                            return orderStatus === item.id;
                        })}
                        colId={item.id}
                        colTitle={item.title}
                        workzoneId={workzoneId}
                    />
                ))}
            </Row>
        </div>
    );
};

export default PlanePage;
