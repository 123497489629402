import { Col, Modal, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetOrderByIdQuery } from '../../../app/api/orderApiSlice';
import { useGetPersonAvatarQuery } from '../../../app/api/profileApiSlice';
import { DateToString } from '../../../helpers/DateHelpers';
import DetailsBlock from './ShowOrderExecutorComponent/DetailsBlock';
import FilesBlock from './ShowOrderExecutorComponent/FilesBlock';
import ExecutionPeriodBlock from './ShowOrderExecutorComponent/ExecutionPeriodBlock';
import CostBlock from './ShowOrderExecutorComponent/CostBlock';

const categoryColors = [
    { id: 1, cls: 'order-category-1' },
    { id: 2, cls: 'order-category-2' },
    { id: 3, cls: 'order-category-3' },
];

const periodShort = [
    { id: 0, name: '24 часа' },
    { id: 1, name: '3 дня' },
    { id: 2, name: '10 дней' },
];

const ShowOrderCustomerModal = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const { workzoneId, orderId } = params;

    const { data: order } = useGetOrderByIdQuery(orderId, { skip: !orderId });
    const { data: personAvatar } = useGetPersonAvatarQuery(order?.executorWorkzone?.personId, {
        skip: !order?.executorWorkzone?.personId,
    });

    const handleClose = () => navigate(`/workzone/${params.workzoneId}/plane`);

    return (
        <>
            <Modal show={true} onHide={handleClose} backdrop='static' size='xl' className='create-order-modal'>
                <div className='type-order-block position-absolute top-0 start-0'>
                    <div
                        className={
                            'd-inline-block order-category ' +
                            categoryColors.find((x) => x.id === order.orderType.orderCategoryId).cls
                        }
                    >
                        {order.orderType?.orderCategory?.name}
                    </div>
                    <div className='d-inline-block order-type'>{order.orderType?.shortName}</div>
                </div>
                <Modal.Header closeButton>
                    <Modal.Title className='mt-3'>{order?.orderType?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={8}>
                            <Row className='mb-3'>
                                <Col md={6}>
                                    <div className='modal-card'>
                                        <div className='card-title'>Пациент</div>
                                        <Row className='mb-3'>
                                            <Col md={6} className='text-label'>
                                                Фамилия
                                            </Col>
                                            <Col md={6} className='text-value'>
                                                {order.patient?.lastName}
                                            </Col>
                                        </Row>
                                        <Row className='mb-3'>
                                            <Col md={6} className='text-label'>
                                                Пол
                                            </Col>
                                            <Col md={6} className='text-value'>
                                                {order.patient?.gender ? 'мужской' : 'женский'}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6} className='text-label'>
                                                Год рождения
                                            </Col>
                                            <Col md={6} className='text-value'>
                                                {order.patient?.yearOfBirth}
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='modal-card h-100 d-flex flex-column justify-content-between'>
                                        <div className='card-title'>Исполнитель</div>
                                        <div className='service-owner d-flex align-items-center gap-3'>
                                            <div className='owner-avatar'>
                                                <img
                                                    src={personAvatar?.photo}
                                                    width={40}
                                                    height={40}
                                                    alt='profile avatar'
                                                    className='profile-avatar '
                                                />
                                            </div>
                                            <div className='owner-about'>
                                                <div className='owner-name mb-1'>
                                                    <Link
                                                        to={`/profile/info/${order?.executorWorkzoneId}`}
                                                        className='owner-name'
                                                    >
                                                        {order?.executorWorkzone?.person?.fio}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col>
                                    <DetailsBlock order={order} />
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col>
                                    <FilesBlock order={order} />
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col>
                                    <ExecutionPeriodBlock
                                        executionPeriod={periodShort.find((x) => x.id === order.executionPeriod).name}
                                    />
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col>
                                    <CostBlock order={order} />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4}>
                            <div className='modal-card'>
                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                    <div className='card-title mb-0'>Исходящий проект</div>
                                    <div className='text-value'>#{order?.id}</div>
                                </div>
                                <Row className='mb-3'>
                                    <Col md={6} className='text-label'>
                                        Заявка создана
                                    </Col>
                                    <Col md={6} className='text-value text-end'>
                                        {DateToString(new Date(order.createdAt))}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} className='text-label'>
                                        Срок выполнения
                                    </Col>
                                    <Col md={6} className='text-value text-end'>
                                        {periodShort.find((x) => x.id === order.executionPeriod).name}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ShowOrderCustomerModal;
