import { useParams } from 'react-router-dom';
import { useGetOrderByIdQuery } from '../../../app/api/orderApiSlice';
import { useSelector } from 'react-redux';
import { selectUserId } from '../../../app/authSlice';
import ShowOrderCustomerModal from './ShowOrderCustomerModal';
import CreateOrderModal from './CreateOrderModal';
import ShowOrderExecutorModal from './ShowOrderExecutorModal';
import { useGetMyWorkzonesQuery } from '../../../app/api/workzoneApiSlice';
import ShowOrderAgreementModal from './ShowOrderAgreementModal';
import ShowOrderInProgressModal from './ShowOrderInProgressModal';

const OrderMainModal = () => {
    const params = useParams();
    const { orderId } = params;

    const userId = useSelector(selectUserId);

    const { data: order, isLoading, isError } = useGetOrderByIdQuery(orderId, { skip: !orderId || orderId === '0' });

    const { data: myWorkzones } = useGetMyWorkzonesQuery();

    if (orderId === '0') {
        return <CreateOrderModal />;
    }
    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (isError) {
        return <div>Error loading order</div>;
    }
    if (order.status === 8) {
        return <CreateOrderModal />;
    } else if (order.status === 0 || order.status === 1) {
        if (myWorkzones?.some((x) => x.id === order.workzoneId)) {
            return <ShowOrderCustomerModal />;
        } else {
            return <ShowOrderExecutorModal />;
        }
    } else if (order.status === 2) {
        return <ShowOrderAgreementModal />;
    } else if (order.status === 3) {
        return <ShowOrderInProgressModal />;
    }
};

export default OrderMainModal;
