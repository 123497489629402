import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import DetailsPanel from '../DetailsPanel';

const DetailsBlock = (props) => {
    const {
        order,
        isOwner,
        currentAgreement,
        isDetailsAccepted,
        setIsDetailsAccepted,
        detailsComments,
        setDetailsComments,
        lastOrderAgreement,
    } = props;
    const [accordionIsOpen, setAccordionIsOpen] = useState(currentAgreement ? true : false);

    console.log('lastOrderAgreementDetails', lastOrderAgreement);

    return (
        <div
            className={
                'modal-card px-3 py-2' + (lastOrderAgreement?.isDetailsAccepted === false ? ' danger-border' : '')
            }
        >
            <div
                className={
                    'd-flex justify-content-between align-items-center accordion-header' +
                    (accordionIsOpen ? ' opened' : '')
                }
                onClick={() => setAccordionIsOpen(!accordionIsOpen)}
            >
                <div className='header-block-font-size mb-0'>Детали</div>
                <div>
                    <img src='/images/icons/arrow-down.svg' height={16} className='accordion-arrow' />
                </div>
            </div>
            {accordionIsOpen && (
                <>
                    <Row className='pt-2'>
                        <Col md={8}>
                            <Row className='mb-3 text-description'>
                                <Col>
                                    {order?.orderDetails?.map((item) => (
                                        <DetailsPanel
                                            detail={{
                                                ...item,
                                                teeth: JSON.parse(item?.teeth),
                                            }}
                                            key={item?.id}
                                            canEdit={false}
                                        />
                                    ))}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className='text-value-color simple-text-font-size mb-2'>Описание</div>
                                    <div className='text-description'>
                                        <div className='details-block'>
                                            <div className='details-comments mt-2 mb-2'>
                                                <div className='details-text'>{order?.description}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4}>
                            <div className='mb-4'>
                                <img className='img-fluid' src='/images/icons/tooth-up.svg' alt='' />
                            </div>
                            <div>
                                <img className='img-fluid' src='/images/icons/tooth-down.svg' alt='' />
                            </div>
                        </Col>
                    </Row>
                    {lastOrderAgreement &&
                        (!lastOrderAgreement?.isDetailsAccepted ? (
                            <Row>
                                <Col>
                                    <div className='danger-label'>
                                        <img
                                            src='/images/icons/info-circle-danger.svg'
                                            className='me-1 align-text-top'
                                            width={11}
                                        />
                                        Есть комментарий {currentAgreement === 'owner' ? 'исполнителя' : 'заказчика'}
                                    </div>
                                    <div className='accept-comment'>{lastOrderAgreement?.detailsComments}</div>
                                </Col>
                            </Row>
                        ) : (
                            <Row>
                                <Col className='accepted-block-text'>
                                    <img
                                        src='/images/icons/double_mark_blue.svg'
                                        className='me-1 align-text-top'
                                        width={11}
                                    />
                                    Согласовано
                                </Col>
                            </Row>
                        ))}
                    {currentAgreement &&
                        ((isOwner && currentAgreement === 'owner' && lastOrderAgreement?.isDetailsAccepted === false) ||
                            (!isOwner &&
                                currentAgreement === 'executor' &&
                                !lastOrderAgreement?.isDetailsAccepted)) && (
                            <>
                                <Row className='mt-4'>
                                    <Col>
                                        <div>
                                            <Form.Check
                                                inline
                                                label='Согласовано'
                                                name='group1'
                                                type='radio'
                                                id='inline-radio-0'
                                                value={true}
                                                onChange={(e) => setIsDetailsAccepted(true)}
                                                checked={isDetailsAccepted === true}
                                            />
                                            <Form.Check
                                                inline
                                                label='Есть комментарий'
                                                name='group1'
                                                type='radio'
                                                id='inline-radio-1'
                                                value={false}
                                                onChange={(e) => setIsDetailsAccepted(false)}
                                                checked={isDetailsAccepted === false}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                {isDetailsAccepted === false && (
                                    <Row className='mt-4'>
                                        <Col>
                                            <Form.Control
                                                as='textarea'
                                                rows={2}
                                                value={detailsComments}
                                                onChange={(e) => setDetailsComments(e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </>
                        )}
                </>
            )}
        </div>
    );
};

export default DetailsBlock;
