import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import OrderFiles from '../OrderFiles';

const FilesBlock = (props) => {
    const {
        order,
        isOwner,
        currentAgreement,
        isFileScansAccepted,
        setIsFileScansAccepted,
        fileScansComments,
        setFileScansComments,
        isFileKtAccepted,
        setIsFileKtAccepted,
        fileKtComments,
        setFileKtComments,
        isFileMrtAccepted,
        setIsFileMrtAccepted,
        fileMrtComments,
        setFileMrtComments,
        isFilePhotoAccepted,
        setIsFilePhotoAccepted,
        filePhotoComments,
        setFilePhotoComments,
        isFileOtherAccepted,
        setIsFileOtherAccepted,
        fileOtherComments,
        setFileOtherComments,
        lastOrderAgreement,
    } = props;
    const [accordionIsOpen, setAccordionIsOpen] = useState(currentAgreement ? true : false);

    return (
        <div
            className={
                'modal-card px-3 py-2' +
                (lastOrderAgreement?.isFileKtAccepted === false ||
                lastOrderAgreement?.isFileMrtAccepted === false ||
                lastOrderAgreement?.isFileOtherAccepted === false ||
                lastOrderAgreement?.isFilePhotoAccepted === false ||
                lastOrderAgreement?.isFileScansAccepted === false
                    ? ' danger-border'
                    : '')
            }
        >
            <div
                className={
                    'd-flex justify-content-between align-items-center accordion-header' +
                    (accordionIsOpen ? ' opened' : '')
                }
                onClick={() => setAccordionIsOpen(!accordionIsOpen)}
            >
                <div className='header-block-font-size mb-0'>Прикреплённые файлы</div>
                <div>
                    <img src='/images/icons/arrow-down.svg' height={16} className='accordion-arrow' />
                </div>
            </div>
            {accordionIsOpen && (
                <Row className='pt-2'>
                    <Col>
                        <Row className='mb-3 text-description'>
                            <Col>
                                <OrderFiles
                                    orderId={order?.id}
                                    canEdit={false}
                                    isOwner={isOwner}
                                    currentAgreement={currentAgreement}
                                    isFileScansAccepted={isFileScansAccepted}
                                    setIsFileScansAccepted={setIsFileScansAccepted}
                                    fileScansComments={fileScansComments}
                                    setFileScansComments={setFileScansComments}
                                    isFileKtAccepted={isFileKtAccepted}
                                    setIsFileKtAccepted={setIsFileKtAccepted}
                                    fileKtComments={fileKtComments}
                                    setFileKtComments={setFileKtComments}
                                    isFileMrtAccepted={isFileMrtAccepted}
                                    setIsFileMrtAccepted={setIsFileMrtAccepted}
                                    fileMrtComments={fileMrtComments}
                                    setFileMrtComments={setFileMrtComments}
                                    isFilePhotoAccepted={isFilePhotoAccepted}
                                    setIsFilePhotoAccepted={setIsFilePhotoAccepted}
                                    filePhotoComments={filePhotoComments}
                                    setFilePhotoComments={setFilePhotoComments}
                                    isFileOtherAccepted={isFileOtherAccepted}
                                    setIsFileOtherAccepted={setIsFileOtherAccepted}
                                    fileOtherComments={fileOtherComments}
                                    setFileOtherComments={setFileOtherComments}
                                    lastOrderAgreement={lastOrderAgreement}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default FilesBlock;
