import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

const ExecutionPeriodBlock = (props) => {
    const {
        executionPeriod,
        currentAgreement,
        isOwner,
        isExecutionPeriodAccepted,
        setIsExecutionPeriodAccepted,
        executionPeriodComments,
        setExecutionPeriodComments,
        lastOrderAgreement,
    } = props;
    const [accordionIsOpen, setAccordionIsOpen] = useState(currentAgreement ? true : false);
    return (
        <div
            className={
                'modal-card px-3 py-2' +
                (lastOrderAgreement?.isExecutionPeriodAccepted === false ? ' danger-border' : '')
            }
        >
            <div
                className={
                    'd-flex justify-content-between align-items-center accordion-header' +
                    (accordionIsOpen ? ' opened' : '')
                }
                onClick={() => setAccordionIsOpen(!accordionIsOpen)}
            >
                <div className='header-block-font-size mb-0'>Срок выполнения</div>
                <div>
                    <img src='/images/icons/arrow-down.svg' height={16} className='accordion-arrow' />
                </div>
            </div>
            {accordionIsOpen && (
                <>
                    <Row className='pt-2'>
                        <Col>
                            <Row className='mb-1 text-description'>
                                <Col>
                                    <span className='text-label-color simple-text-font-size'>Срок выполнения:</span>{' '}
                                    <span className='text-value-color simple-text-font-size'>{executionPeriod}</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {lastOrderAgreement &&
                        (!lastOrderAgreement?.isExecutionPeriodAccepted ? (
                            <Row>
                                <Col>
                                    <div className='danger-label'>
                                        <img
                                            src='/images/icons/info-circle-danger.svg'
                                            className='me-1 align-text-top'
                                            width={11}
                                        />
                                        Есть комментарий {currentAgreement === 'owner' ? 'исполнителя' : 'заказчика'}
                                    </div>
                                    <div className='accept-comment'>{lastOrderAgreement?.executionPeriodComments}</div>
                                </Col>
                            </Row>
                        ) : (
                            <Row>
                                <Col className='accepted-block-text'>
                                    <img
                                        src='/images/icons/double_mark_blue.svg'
                                        className='me-1 align-text-top'
                                        width={11}
                                    />
                                    Согласовано
                                </Col>
                            </Row>
                        ))}
                    {currentAgreement &&
                        ((isOwner &&
                            currentAgreement === 'owner' &&
                            lastOrderAgreement?.isExecutionPeriodAccepted === false) ||
                            (!isOwner &&
                                currentAgreement === 'executor' &&
                                !lastOrderAgreement?.isExecutionPeriodAccepted)) && (
                            <>
                                <Row className='mt-4'>
                                    <Col>
                                        <div>
                                            <Form.Check
                                                inline
                                                label='Согласовано'
                                                name='execution-period-group'
                                                type='radio'
                                                id='execution-period-radio-0'
                                                value={true}
                                                onChange={(e) => setIsExecutionPeriodAccepted(true)}
                                                checked={isExecutionPeriodAccepted === true}
                                            />
                                            <Form.Check
                                                inline
                                                label='Есть комментарий'
                                                name='execution-period-group'
                                                type='radio'
                                                id='execution-period-radio-1'
                                                value={false}
                                                onChange={(e) => setIsExecutionPeriodAccepted(false)}
                                                checked={isExecutionPeriodAccepted === false}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                {isExecutionPeriodAccepted === false && (
                                    <Row className='mt-4'>
                                        <Col>
                                            <Form.Control
                                                as='textarea'
                                                rows={2}
                                                value={executionPeriodComments}
                                                onChange={(e) => setExecutionPeriodComments(e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </>
                        )}
                </>
            )}
        </div>
    );
};

export default ExecutionPeriodBlock;
