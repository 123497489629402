import { apiSlice } from "./apiSlice";

export const orderApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getOrderCategories: builder.query({
            query: () => "api/orders/getordercategories",
        }),
        getOrderFileTypes: builder.query({
            query: () => "api/orders/GetOrderFileTypes",
        }),
        getOrders: builder.query({
            query: (data) =>
                `api/orders/getordersbyworkzone?workzoneId=${data.workzoneId}&sort=${data.sort}&direction=${data.direction}`,
            providesTags: () => [{ type: "Orders", id: "List" }],
        }),
        getOrderById: builder.query({
            query: (orderId) => `api/orders/${orderId}`,
            providesTags: (result) => [{ type: "Order", id: result.id }],
        }),
        createOrder: builder.mutation({
            query: (data) => ({
                url: "api/orders",
                method: "POST",
                body: { ...data },
            }),
            invalidatesTags: [{ type: "Orders", id: "List" }],
        }),
        updateOrder: builder.mutation({
            query: (data) => ({
                url: "api/orders/" + data.id,
                method: "PUT",
                body: { ...data },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Orders", id: "List" },
                { type: "Order", id: result.id },
            ],
        }),
        deleteOrder: builder.mutation({
            query: (entityId) => ({
                url: "api/orders/" + entityId,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "Orders", id: "List" }],
        }),
        uploadDoc: builder.mutation({
            query: (data) => ({
                url: `api/orders/uploadDocument?typeId=${data.typeId}&orderId=${data.orderId}`,
                method: "POST",
                body: data.formFile,
            }),
            invalidatesTags: [{ type: "OrderFiles", id: "List" }],
        }),
        downloadDoc: builder.mutation({
            query: (docId) => ({
                url: `api/orders/DownloadDocument?id=${docId}`,
                responseHandler: async (response) => {
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    return { url };
                },
            }),
        }),
        deleteDoc: builder.mutation({
            query: (entityId) => ({
                url: "api/orders/deleteDocument/" + entityId,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "OrderFiles", id: "List" }],
        }),
        createDetail: builder.mutation({
            query: (data) => ({
                url: "api/orders/createDetail",
                method: "POST",
                body: { ...data },
            }),
            invalidatesTags: [{ type: "Orders", id: "List" }],
        }),
        updateDetail: builder.mutation({
            query: (data) => ({
                url: "api/orders/updateDetail/" + data.id,
                method: "PUT",
                body: { ...data },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Orders", id: "List" },
                { type: "Order", id: result.orderId },
            ],
        }),
        deleteDetail: builder.mutation({
            query: (entityId) => ({
                url: "api/orders/deleteDetail/" + entityId,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "Orders", id: "List" }],
        }),
        getOrdersPin: builder.query({
            query: () => "api/orders/GetOrderPins",
            providesTags: () => [{ type: "OrdersPin", id: "List" }],
        }),
        addOrderPin: builder.mutation({
            query: (orderId) => ({
                url: "api/orders/AddPin?orderId=" + orderId,
                method: "POST",
            }),
            invalidatesTags: [{ type: "OrdersPin", id: "List" }],
        }),
        removeOrderPin: builder.mutation({
            query: (orderId) => ({
                url: "api/orders/RemovePin?orderId=" + orderId,
                method: "POST",
            }),
            invalidatesTags: [{ type: "OrdersPin", id: "List" }],
        }),
        getOrderFiles: builder.query({
            query: (orderId) => "api/orders/GetOrderFiles?orderId=" + orderId,
            providesTags: () => [{ type: "OrderFiles", id: "List" }],
        }),
        acceptIncomingOrder: builder.mutation({
            query: (orderId) => ({
                url: "api/orders/acceptIncomingOrder?orderId=" + orderId,
                method: "PUT",
            }),
            invalidatesTags: (result) => [
                { type: "Orders", id: "List" },
                { type: "Order", id: result.id },
            ],
        }),
        getLastOrderAgreement: builder.query({
            query: (orderId) =>
                "api/orders/GetLastOrderAgreement?orderId=" + orderId,
            providesTags: () => [{ type: "OrderAgreement", id: "List" }],
        }),
        createOrderAgreement: builder.mutation({
            query: (data) => ({
                url: "api/orders/CreateOrderAgreement",
                method: "POST",
                body: { ...data },
            }),
            invalidatesTags: (result) => [
                { type: "OrderAgreement", id: "List" },
                { type: "Orders", id: "List" },
                { type: "Order", id: result.orderId },
            ],
        }),
    }),
});

export const {
    useGetOrderCategoriesQuery,
    useGetOrdersQuery,
    useLazyGetOrdersQuery,
    useCreateOrderMutation,
    useUploadDocMutation,
    useDownloadDocMutation,
    useDeleteOrderMutation,
    useCreateDetailMutation,
    useGetOrdersPinQuery,
    useAddOrderPinMutation,
    useRemoveOrderPinMutation,
    useGetOrderByIdQuery,
    useLazyGetOrderByIdQuery,
    useUpdateOrderMutation,
    useUpdateDetailMutation,
    useDeleteDetailMutation,
    useGetOrderFileTypesQuery,
    useGetOrderFilesQuery,
    useDeleteDocMutation,
    useAcceptIncomingOrderMutation,
    useGetLastOrderAgreementQuery,
    useCreateOrderAgreementMutation,
} = orderApiSlice;
