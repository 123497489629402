import { useState } from 'react';

const OrderFilesAccordion = (props) => {
    const { children, title, opend } = props;

    const [isOpen, setIsOpen] = useState(opend ? true : false);

    return (
        <div className='accordion-custom'>
            <div
                className={'accordion-header d-flex justify-content-between mb-1' + (isOpen ? ' opened' : '')}
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className='header-block-font-size'>{title}</div>
                <div>
                    <img src='/images/icons/arrow-down.svg' height={16} />
                </div>
            </div>
            {isOpen && <div className='accordion-body px-4'>{children}</div>}
        </div>
    );
};

export default OrderFilesAccordion;
