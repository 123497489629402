import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getUser, logout, renewToken } from "../../services/AuthService";
import { API_SERVER_URL } from "../../services/UrlConstants";

const baseQuery = fetchBaseQuery({
    baseUrl: API_SERVER_URL,
    credentials: "include",
    prepareHeaders: async (headers) => {
        const user = await getUser();
        const accessToken = user?.access_token;
        if (accessToken) {
            headers.set("authorization", `Bearer ${accessToken}`);
        }

        return headers;
    },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result?.error?.status === 401) {
        console.log("i am is unauthorized");
        await renewToken();
        const user = await getUser();
        console.log("user", user);
        if (user) {
            result = await baseQuery(args, api, extraOptions);
        } else {
            console.log("ooops! Can not renew token!!!");
        }
    }

    return result;
};

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: [
        "Resources",
        "Profiles",
        "Profile",
        "Workzones",
        "Patients",
        "Orders",
        "OrdersPin",
        "OrderFiles",
        "OrderAgreement",
        "ChatMessage",
        "ChatMessages",
        "Projects",
        "Project",
        "Services",
        "Service",
    ],
    endpoints: (builder) => ({}),
});
