import { Col, Form, Row } from 'react-bootstrap';
import OrderFilesAccordion from '../OrderFilesAccordion';
import OrderFileDownloader from '../OrderFileDownloader';
import { useDeleteDocMutation, useDownloadDocMutation } from '../../../../app/api/orderApiSlice';

const AccordionOther = (props) => {
    const {
        files,
        orderId,
        canEdit = true,
        isOwner,
        currentAgreement,
        isFileOtherAccepted,
        setIsFileOtherAccepted,
        fileOtherComments,
        setFileOtherComments,
        lastOrderAgreement,
    } = props;

    const [downloadFile] = useDownloadDocMutation();
    const [deleteDoc] = useDeleteDocMutation();

    const downloadScan = async (fileId) => {
        const file = await downloadFile(fileId);
        const a = document.createElement('a');
        a.href = file.data.url;
        a.download = files.find((x) => x.id === fileId).fileName;
        a.click();
        URL.revokeObjectURL(file.data.url);
    };

    const deleteFile = async (fileId) => {
        await deleteDoc(fileId);
    };

    return (
        <OrderFilesAccordion title='Прочее' opend={currentAgreement}>
            {(canEdit ||
                (isOwner && currentAgreement === 'owner' && !lastOrderAgreement?.isFileOtherAccepted) ||
                (!isOwner && currentAgreement === 'executor' && !lastOrderAgreement?.isFileOtherAccepted)) && (
                <OrderFileDownloader
                    infoText='Файлы загружать архивом: .zip, .rar. Максимальный размер файла 10 МБ.'
                    fileType={12}
                    orderId={orderId}
                    accept={{ 'application/zip': ['.rar'] }}
                />
            )}
            {files?.length > 0 && (
                <div className='order-files-block mb-3'>
                    <div className='order-files-title mb-2'>Прикреплённые файлы</div>
                    <div>
                        {files.map((item) => (
                            <div key={item.id} className='mb-2 d-flex justify-content-between align-items-center'>
                                <div className='order-file'>{item.fileName}</div>
                                <div>
                                    <button className='btn order-file-action-btn' onClick={() => downloadScan(item.id)}>
                                        Скачать
                                    </button>
                                    {canEdit && (
                                        <button
                                            className='btn order-file-action-btn'
                                            onClick={() => deleteFile(item.id)}
                                        >
                                            Удалить
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {lastOrderAgreement &&
                (!lastOrderAgreement?.isFileOtherAccepted ? (
                    <Row>
                        <Col>
                            <div className='danger-label'>
                                <img
                                    src='/images/icons/info-circle-danger.svg'
                                    className='me-1 align-text-top'
                                    width={11}
                                />
                                Есть комментарий {currentAgreement === 'owner' ? 'исполнителя' : 'заказчика'}
                            </div>
                            <div className='accept-comment'>{lastOrderAgreement?.fileOtherComments}</div>
                        </Col>
                    </Row>
                ) : (
                    <Row className='mb-2'>
                        <Col className='accepted-block-text'>
                            <img src='/images/icons/double_mark_blue.svg' className='me-1 align-text-top' width={11} />
                            Согласовано
                        </Col>
                    </Row>
                ))}
            {currentAgreement &&
                ((isOwner && currentAgreement === 'owner' && !lastOrderAgreement?.isFileOtherAccepted) ||
                    (!isOwner && currentAgreement === 'executor' && !lastOrderAgreement?.isFileOtherAccepted)) && (
                    <>
                        <Row className='mt-4 mb-4'>
                            <Col>
                                <div className='lh-base'>
                                    <Form.Check
                                        inline
                                        label='Согласовано'
                                        name='fileOther'
                                        type='radio'
                                        id='inline-radio-fileOther-0'
                                        value={true}
                                        onChange={(e) => setIsFileOtherAccepted(true)}
                                        checked={isFileOtherAccepted === true}
                                    />
                                    <Form.Check
                                        inline
                                        label='Есть комментарий'
                                        name='fileOther'
                                        type='radio'
                                        id='inline-radio-fileOther-1'
                                        value={false}
                                        onChange={(e) => setIsFileOtherAccepted(false)}
                                        checked={isFileOtherAccepted === false}
                                    />
                                </div>
                            </Col>
                        </Row>
                        {isFileOtherAccepted === false && (
                            <Row className='mb-4'>
                                <Col>
                                    <Form.Control
                                        as='textarea'
                                        rows={2}
                                        value={fileOtherComments}
                                        onChange={(e) => setFileOtherComments(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
        </OrderFilesAccordion>
    );
};

export default AccordionOther;
