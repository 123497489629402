import { Col, Container, Form, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetServiceByIdQuery } from '../../../app/api/serviceApiSlice';
import { useGetPersonAvatarQuery } from '../../../app/api/profileApiSlice';
import { useState } from 'react';
import { useGetMyWorkzonesQuery } from '../../../app/api/workzoneApiSlice';

const categoryColors = [
    { id: 1, cls: 'order-category-1' },
    { id: 2, cls: 'order-category-2' },
    { id: 3, cls: 'order-category-3' },
];

const ServiceCard = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const { serviceId } = params;
    const { data: service } = useGetServiceByIdQuery(serviceId);
    const { data: avatar } = useGetPersonAvatarQuery(service?.workzone?.personId, {
        skip: !service?.workzone?.person?.photoUrl,
    });
    const { data: myWorkzones } = useGetMyWorkzonesQuery();

    const [additionalChecked, setAdditionalChecked] = useState([]);

    const handleAdditionalChange = (e, value) => {
        let updatedList = [...additionalChecked];
        if (updatedList.indexOf(value) === -1) {
            updatedList = [...updatedList, value];
        } else {
            updatedList.splice(updatedList.indexOf(value), 1);
        }
        setAdditionalChecked(updatedList);
    };

    const orderService = () => {
        navigate(`/workzone/${myWorkzones[0].id}/plane/orders/0?serviceId=${serviceId}`);
    };

    console.log('service', service);

    return (
        <Container className='pt-5'>
            <Row>
                <Col md={8}>
                    <div className='service-card'>
                        <div className='service-card-title d-flex align-items-center gap-3'>
                            <img
                                src={service?.workzone?.person?.photoUrl ? avatar?.photo : '/images/icons/person.png'}
                                alt='profile avatar'
                                width={54}
                                height={54}
                                className='profile-avatar'
                            />
                            <div className='owner-about'>
                                <div className='mb-1'>
                                    <Link to={`/profile/info/${service?.workzoneId}`} className='owner-name'>
                                        {service?.workzone?.person?.fio}
                                    </Link>
                                </div>
                                <div className='owner-rating'>Нет оценок</div>
                            </div>
                        </div>
                        <div className='service-card-description'>
                            <div
                                className={
                                    'd-inline-block order-category ' +
                                    categoryColors.find((x) => x.id === service?.orderType?.orderCategoryId)?.cls
                                }
                            >
                                {service?.orderType?.orderCategory?.name}
                            </div>
                            <div className='service-card-description-title'>{service?.orderType?.name}</div>
                            <div className='service-card-description-text'>{service?.description}</div>
                            <div className='service-card-description-title'>Объём выполняемых работ</div>
                            <div className='service-card-description-text'>{service?.scopeWork}</div>
                            <div className='service-card-description-title'>Необходимые диагностики</div>
                            <div className='service-card-description-text'>{service?.diagnosticsNeeded}</div>
                            <div className='service-card-description-title'>Дополнительные услуги</div>
                            {service?.serviceAdditionals?.map((item) => (
                                <div className='additional-service' key={item.id}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <Form.Check
                                            label={item.name}
                                            className='text-label'
                                            onChange={(e) => handleAdditionalChange(e, item.id)}
                                            checked={additionalChecked.includes(item.id)}
                                            type='checkbox'
                                            id={'additionalService_' + item.id}
                                            value={item.id}
                                        />
                                        <div className='additional-service-price'>{item.price} ₽</div>
                                    </div>
                                    {item.isDuration && (
                                        <div className='d-flex align-items-center ms-4'>
                                            <img
                                                src='/images/icons/info-circle.svg'
                                                alt='info'
                                                width={16}
                                                height={16}
                                                className='me-2'
                                            />
                                            <span>
                                                Эта диагностика увеличивает время выполнения задачи на {item.duration}{' '}
                                                {item.duration === 1
                                                    ? 'день'
                                                    : item.duration === 2 || item.duration === 3 || item.duration === 4
                                                    ? 'дня'
                                                    : 'дней'}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            ))}
                            <div className='mt-4 d-flex justify-content-between'>
                                <button className='btn btn-primary' onClick={orderService}>
                                    Заказать
                                </button>
                                <button className='btn btn-only-text-primary p-0' disabled>
                                    Написать сообщение
                                </button>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={4}>
                    <div className='service-pay-info'>
                        <div className='d-flex justify-content-between mb-3'>
                            <div>Стоимость</div>
                            <div className='service-pay-info-value'>от {service?.price} ₽</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>Срок выполнения</div>
                            <div className='service-pay-info-value'>
                                {service?.duration}{' '}
                                {service?.duration === 1
                                    ? 'день'
                                    : service?.duration === 2 || service?.duration === 3 || service?.duration === 4
                                    ? 'дня'
                                    : 'дней'}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default ServiceCard;
