import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

const CostBlock = (props) => {
    const { order } = props;
    const [accordionIsOpen, setAccordionIsOpen] = useState(false);
    return (
        <div className='modal-card px-3 py-2'>
            <div
                className={
                    'd-flex justify-content-between align-items-center accordion-header' +
                    (accordionIsOpen ? ' opened' : '')
                }
                onClick={() => setAccordionIsOpen(!accordionIsOpen)}
            >
                <div className='header-block-font-size mb-0'>Стоимость</div>
                <div>
                    <img src='/images/icons/arrow-down.svg' height={16} className='accordion-arrow' />
                </div>
            </div>
            {accordionIsOpen && (
                <Row className='pt-2'>
                    <Col>
                        <Row className='mb-3 text-description'>
                            <Col>
                                <table className='table simple-table'>
                                    <thead>
                                        <tr className='border-bottom-0'>
                                            <th>Услуга</th>
                                            <th className='text-center'>Количество</th>
                                            <th className='text-center'>Цена, ₽</th>
                                            <th className='text-center'>Сумма, ₽</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{order.orderType.name}</td>
                                            <td className='text-center'>{order.quantity}</td>
                                            <td className='text-center'>{order.cost}</td>
                                            <td className='text-center'>{order.cost * order.quantity}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className='text-end cost-total'>
                                    Итого: <span className='cost-value'>{order.cost * order.quantity}</span>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default CostBlock;
