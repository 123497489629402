import { Button, Col, Row } from "react-bootstrap";

const ActionButtonsBlock = (props) => {
    const { orderId, isOwner, acceptOrder, currentAgreement } = props;
    return (
        <>
            {isOwner ? (
                currentAgreement === "executor" ? (
                    <Row>
                        <Col>
                            <Button
                                variant="outline-primary"
                                className="px-3 py-1 rounded-2"
                            >
                                Удалить заказ
                            </Button>
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col>
                            <Button
                                variant="primary me-3"
                                className="px-3 py-1 rounded-2"
                                onClick={acceptOrder}
                            >
                                Подтвердить
                            </Button>
                            <Button
                                variant="outline-primary"
                                className="px-3 py-1 rounded-2"
                            >
                                Удалить заказ
                            </Button>
                        </Col>
                    </Row>
                )
            ) : currentAgreement === "owner" ? (
                <Row>
                    <Col>
                        <Button
                            variant="outline-primary"
                            className="px-3 py-1 rounded-2"
                        >
                            Отказаться от заказа
                        </Button>
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Col>
                        <Button
                            variant="primary me-3"
                            className="px-3 py-1 rounded-2"
                            onClick={acceptOrder}
                        >
                            Подтвердить
                        </Button>
                        <Button
                            variant="outline-primary"
                            className="px-3 py-1 rounded-2"
                        >
                            Отказаться от заказа
                        </Button>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default ActionButtonsBlock;
