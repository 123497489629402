import { useGetOrderFilesQuery, useGetOrderFileTypesQuery } from '../../../app/api/orderApiSlice';
import AccordionKT from './AccordionBlocks/AccordionKT';
import AccordionMrt from './AccordionBlocks/AccordionMrt';
import AccordionOther from './AccordionBlocks/AccordionOther';
import AccordionPhoto from './AccordionBlocks/AccordionPhoto';
import AccordionScans from './AccordionBlocks/AccordionScans';

const OrderFiles = (props) => {
    const {
        orderId,
        canEdit = true,
        isOwner,
        currentAgreement,
        isFileScansAccepted,
        setIsFileScansAccepted,
        fileScansComments,
        setFileScansComments,
        isFileKtAccepted,
        setIsFileKtAccepted,
        fileKtComments,
        setFileKtComments,
        isFileMrtAccepted,
        setIsFileMrtAccepted,
        fileMrtComments,
        setFileMrtComments,
        isFilePhotoAccepted,
        setIsFilePhotoAccepted,
        filePhotoComments,
        setFilePhotoComments,
        isFileOtherAccepted,
        setIsFileOtherAccepted,
        fileOtherComments,
        setFileOtherComments,
        lastOrderAgreement,
    } = props;

    const { data: fileTypes } = useGetOrderFileTypesQuery();
    const { data: files } = useGetOrderFilesQuery(orderId);

    console.log('files', files);

    return (
        <div>
            <AccordionScans
                files={files?.filter((x) => x.type.categoryId === 1)}
                fileTypes={fileTypes?.filter((x) => x.categoryId === 1)}
                orderId={orderId}
                canEdit={canEdit}
                isOwner={isOwner}
                currentAgreement={currentAgreement}
                isFileScansAccepted={isFileScansAccepted}
                setIsFileScansAccepted={setIsFileScansAccepted}
                fileScansComments={fileScansComments}
                setFileScansComments={setFileScansComments}
                lastOrderAgreement={lastOrderAgreement}
            />
            <AccordionKT
                files={files?.filter((x) => x.type.categoryId === 2)}
                orderId={orderId}
                canEdit={canEdit}
                isOwner={isOwner}
                currentAgreement={currentAgreement}
                isFileKtAccepted={isFileKtAccepted}
                setIsFileKtAccepted={setIsFileKtAccepted}
                fileKtComments={fileKtComments}
                setFileKtComments={setFileKtComments}
                lastOrderAgreement={lastOrderAgreement}
            />
            <AccordionMrt
                files={files?.filter((x) => x.type.categoryId === 3)}
                orderId={orderId}
                canEdit={canEdit}
                isOwner={isOwner}
                currentAgreement={currentAgreement}
                isFileMrtAccepted={isFileMrtAccepted}
                setIsFileMrtAccepted={setIsFileMrtAccepted}
                fileMrtComments={fileMrtComments}
                setFileMrtComments={setFileMrtComments}
                lastOrderAgreement={lastOrderAgreement}
            />
            <AccordionPhoto
                files={files?.filter((x) => x.type.categoryId === 4)}
                fileTypes={fileTypes?.filter((x) => x.categoryId === 4)}
                orderId={orderId}
                canEdit={canEdit}
                isOwner={isOwner}
                currentAgreement={currentAgreement}
                isFilePhotoAccepted={isFilePhotoAccepted}
                setIsFilePhotoAccepted={setIsFilePhotoAccepted}
                filePhotoComments={filePhotoComments}
                setFilePhotoComments={setFilePhotoComments}
                lastOrderAgreement={lastOrderAgreement}
            />
            <AccordionOther
                files={files?.filter((x) => x.type.categoryId === 5)}
                orderId={orderId}
                canEdit={canEdit}
                isOwner={isOwner}
                currentAgreement={currentAgreement}
                isFileOtherAccepted={isFileOtherAccepted}
                setIsFileOtherAccepted={setIsFileOtherAccepted}
                fileOtherComments={fileOtherComments}
                setFileOtherComments={setFileOtherComments}
                lastOrderAgreement={lastOrderAgreement}
            />
        </div>
    );
};

export default OrderFiles;
