import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
    useCreateOrderAgreementMutation,
    useGetLastOrderAgreementQuery,
    useGetOrderByIdQuery,
} from '../../../app/api/orderApiSlice';
import { useGetPersonAvatarQuery } from '../../../app/api/profileApiSlice';
import { useEffect, useState } from 'react';
import { useGetMyWorkzonesQuery } from '../../../app/api/workzoneApiSlice';
import DetailsBlock from './ShowOrderExecutorComponent/DetailsBlock';
import FilesBlock from './ShowOrderExecutorComponent/FilesBlock';
import ExecutionPeriodBlock from './ShowOrderExecutorComponent/ExecutionPeriodBlock';
import CostBlock from './ShowOrderExecutorComponent/CostBlock';
import { DateToString } from '../../../helpers/DateHelpers';
import ActionButtonsBlock from './ShowOrderExecutorComponent/ActionButtonsBlock';

const categoryColors = [
    { id: 1, cls: 'order-category-1' },
    { id: 2, cls: 'order-category-2' },
    { id: 3, cls: 'order-category-3' },
];

const periodShort = [
    { id: 0, name: '24 часа' },
    { id: 1, name: '3 дня' },
    { id: 2, name: '10 дней' },
];

const ShowOrderAgreementModal = (props) => {
    const params = useParams();
    const { workzoneId, orderId } = params;

    const navigate = useNavigate();
    const handleClose = () => navigate(`/workzone/${workzoneId}/plane`);

    const [isOwner, setIsOwner] = useState(false);
    const [currentAgreement, setCurrentAgreement] = useState('executor');

    const [isDetailsAccepted, setIsDetailsAccepted] = useState(true);
    const [detailsComments, setDetailsComments] = useState('');
    const [isFileScansAccepted, setIsFileScansAccepted] = useState(true);
    const [fileScansComments, setFileScansComments] = useState('');
    const [isFileKtAccepted, setIsFileKtAccepted] = useState(true);
    const [fileKtComments, setFileKtComments] = useState('');
    const [isFileMrtAccepted, setIsFileMrtAccepted] = useState(true);
    const [fileMrtComments, setFileMrtComments] = useState('');
    const [isFilePhotoAccepted, setIsFilePhotoAccepted] = useState(true);
    const [filePhotoComments, setFilePhotoComments] = useState('');
    const [isFileOtherAccepted, setIsFileOtherAccepted] = useState(true);
    const [fileOtherComments, setFileOtherComments] = useState('');
    const [isExecutionPeriodAccepted, setIsExecutionPeriodAccepted] = useState(true);
    const [executionPeriodComments, setExecutionPeriodComments] = useState('');

    const { data: order } = useGetOrderByIdQuery(orderId, { skip: !orderId });
    const { data: personAvatar } = useGetPersonAvatarQuery(order?.executorWorkzone?.personId, {
        skip: !order?.executorWorkzone?.personId,
    });
    const { data: ownerAvatar } = useGetPersonAvatarQuery(order?.workzone?.personId, {
        skip: !order?.workzone?.personId,
    });
    const { data: myWorkzones } = useGetMyWorkzonesQuery();
    const { data: lastOrderAgreement } = useGetLastOrderAgreementQuery(orderId, { skip: !orderId });

    const [createOrderAgreement, { isLoading: acceptOrderLoading }] = useCreateOrderAgreementMutation();

    useEffect(() => {
        if (order && myWorkzones) {
            setIsOwner(myWorkzones?.some((x) => x.id === order.workzoneId));
        }
    }, [order, myWorkzones]);

    useEffect(() => {
        if (lastOrderAgreement) {
            if (lastOrderAgreement === null || lastOrderAgreement.personType === 1) {
                setCurrentAgreement('executor');
            } else {
                setCurrentAgreement('owner');
            }
        }
    }, [lastOrderAgreement]);

    const acceptOrder = async () => {
        await createOrderAgreement({
            orderId: orderId,
            isDetailsAccepted,
            detailsComments,
            isFileScansAccepted,
            fileScansComments,
            isFileKtAccepted,
            fileKtComments,
            isFileMrtAccepted,
            fileMrtComments,
            isFilePhotoAccepted,
            filePhotoComments,
            isFileOtherAccepted,
            fileOtherComments,
            isExecutionPeriodAccepted,
            executionPeriodComments,
        });
        handleClose();
    };

    return (
        <>
            <Modal show={true} onHide={handleClose} backdrop='static' size='lg' className='create-order-modal'>
                <div className='type-order-block position-absolute top-0 start-0'>
                    <div
                        className={
                            'd-inline-block order-category ' +
                            categoryColors.find((x) => x.id === order.orderType.orderCategoryId).cls
                        }
                    >
                        {order.orderType?.orderCategory?.name}
                    </div>
                    <div className='d-inline-block order-type'>{order.orderType?.shortName}</div>
                </div>
                <Modal.Header closeButton>
                    <Modal.Title className='mt-3 title-font-size'>{order?.orderType?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={8}>
                            <Row className='mb-3'>
                                <Col md={6}>
                                    <div className='modal-card px-3 py-2'>
                                        <div className='header-block-font-size mb-2'>Пациент</div>
                                        <Row className='mb-1'>
                                            <Col md={6} className='text-label-color simple-text-font-size'>
                                                Фамилия
                                            </Col>
                                            <Col md={6} className='text-value-color simple-text-font-size'>
                                                {order.patient?.lastName}
                                            </Col>
                                        </Row>
                                        <Row className='mb-1'>
                                            <Col md={6} className='text-label-color simple-text-font-size'>
                                                Пол
                                            </Col>
                                            <Col md={6} className='text-value-color simple-text-font-size'>
                                                {order.patient?.gender ? 'мужской' : 'женский'}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6} className='text-label-color simple-text-font-size'>
                                                Год рождения
                                            </Col>
                                            <Col md={6} className='text-value-color simple-text-font-size'>
                                                {order.patient?.yearOfBirth}
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='modal-card h-100 d-flex flex-column justify-content-between px-3 py-2'>
                                        <div className='header-block-font-size mb-2'>Исполнитель</div>
                                        <div className='service-owner d-flex align-items-center gap-3'>
                                            <div className='owner-avatar'>
                                                <img
                                                    src={personAvatar?.photo}
                                                    width={40}
                                                    height={40}
                                                    alt='profile avatar'
                                                    className='profile-avatar '
                                                />
                                            </div>
                                            <div className='owner-about'>
                                                <div className='text-value-color simple-text-font-size mb-1'>
                                                    {order?.executorWorkzone?.person?.fio}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col>
                                    <DetailsBlock
                                        order={order}
                                        isOwner={isOwner}
                                        currentAgreement={currentAgreement}
                                        isDetailsAccepted={isDetailsAccepted}
                                        setIsDetailsAccepted={setIsDetailsAccepted}
                                        detailsComments={detailsComments}
                                        setDetailsComments={setDetailsComments}
                                        lastOrderAgreement={lastOrderAgreement}
                                    />
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col>
                                    <FilesBlock
                                        order={order}
                                        isOwner={isOwner}
                                        currentAgreement={currentAgreement}
                                        isFileKtAccepted={isFileKtAccepted}
                                        setIsFileKtAccepted={setIsFileKtAccepted}
                                        fileKtComments={fileKtComments}
                                        setFileKtComments={setFileKtComments}
                                        isFileMrtAccepted={isFileMrtAccepted}
                                        setIsFileMrtAccepted={setIsFileMrtAccepted}
                                        fileMrtComments={fileMrtComments}
                                        setFileMrtComments={setFileMrtComments}
                                        isFilePhotoAccepted={isFilePhotoAccepted}
                                        setIsFilePhotoAccepted={setIsFilePhotoAccepted}
                                        filePhotoComments={filePhotoComments}
                                        setFilePhotoComments={setFilePhotoComments}
                                        isFileOtherAccepted={isFileOtherAccepted}
                                        setIsFileOtherAccepted={setIsFileOtherAccepted}
                                        fileOtherComments={fileOtherComments}
                                        setFileOtherComments={setFileOtherComments}
                                        isFileScansAccepted={isFileScansAccepted}
                                        setIsFileScansAccepted={setIsFileScansAccepted}
                                        fileScansComments={fileScansComments}
                                        setFileScansComments={setFileScansComments}
                                        lastOrderAgreement={lastOrderAgreement}
                                    />
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col>
                                    <ExecutionPeriodBlock
                                        executionPeriod={periodShort.find((x) => x.id === order.executionPeriod).name}
                                        currentAgreement={currentAgreement}
                                        isOwner={isOwner}
                                        isExecutionPeriodAccepted={isExecutionPeriodAccepted}
                                        setIsExecutionPeriodAccepted={setIsExecutionPeriodAccepted}
                                        executionPeriodComments={executionPeriodComments}
                                        setExecutionPeriodComments={setExecutionPeriodComments}
                                        lastOrderAgreement={lastOrderAgreement}
                                    />
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col>
                                    <CostBlock order={order} />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4}>
                            <div className='modal-card px-3 py-2'>
                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                    <div className='header-block-font-size mb-0'>
                                        {isOwner ? 'Исходящий проект' : 'Входящий проект'}
                                    </div>
                                    <div className='text-value-color simple-text-font-size'>#{order?.id}</div>
                                </div>
                                {!isOwner && (
                                    <div className='service-owner d-flex align-items-center gap-3 mb-3'>
                                        <div className='owner-avatar'>
                                            <img
                                                src={ownerAvatar?.photo}
                                                width={40}
                                                height={40}
                                                alt='profile avatar'
                                                className='profile-avatar '
                                            />
                                        </div>
                                        <div className='owner-about'>
                                            <div className='simple-text-font-size text-value-color mb-1'>
                                                {order?.workzone?.person?.fio}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <Row className='mb-3'>
                                    <Col md={6} className='text-label-color simple-text-font-size'>
                                        Заявка создана
                                    </Col>
                                    <Col md={6} className='text-value-color simple-text-font-size text-end'>
                                        {DateToString(new Date(order.createdAt))}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} className='text-label-color simple-text-font-size'>
                                        Срок выполнения
                                    </Col>
                                    <Col md={6} className='text-value-color simple-text-font-size text-end'>
                                        {periodShort.find((x) => x.id === order.executionPeriod).name}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className='status-block mt-3 pt-2'>
                                            <div className='status-panel'>
                                                <div className='status-item active'>
                                                    <div className='simple-text-font-size pt-1'>
                                                        Согласование объёма работ
                                                    </div>
                                                    <div className='status-date simple-text-font-size'>
                                                        {DateToString(new Date(order.createdAt), true)}
                                                    </div>
                                                </div>
                                                <div className='status-item'>
                                                    <div className='simple-text-font-size pt-1'>В работе</div>
                                                </div>
                                                <div className='status-item'>
                                                    <div className='simple-text-font-size pt-1'>Согласование</div>
                                                </div>
                                                <div className='status-item'>
                                                    <div className='simple-text-font-size pt-1'>Доработка</div>
                                                </div>
                                                <div className='status-item'>
                                                    <div className='simple-text-font-size pt-1'>Выполнено</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <ActionButtonsBlock
                        orderId={orderId}
                        isOwner={isOwner}
                        acceptOrder={acceptOrder}
                        currentAgreement={currentAgreement}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ShowOrderAgreementModal;
